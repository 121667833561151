(function ($) {


    $('header .btn-mobile-menu').on('click', function(e){
        e.preventDefault();
        if($(this).parents('body').hasClass('open-mobile-menu')){
            $(this).parents('body').removeClass('open-mobile-menu');
            $(this).unbind('mouseenter mouseleave')
        }else{
            $(this).parents('body').addClass('open-mobile-menu');
        }

    });
    $('header .container-mobile-menu .btn-menu-close').on('click', function(e){
        e.preventDefault();
        $(this).parents('body').removeClass('open-mobile-menu');
    });




}(jQuery));